const html = document.getElementsByTagName('html')[0];

// Menu principal
let menuSwitch = document.getElementById('menu-top').getElementsByClassName('navbar-switch')[0];
menuSwitch.onclick = function(){
	if(html.classList.contains('open-menu')){
		html.classList.remove('open-menu');
	} else {
		html.classList.add('open-menu');
	}
	return false;
};
document.addEventListener("scroll", (e) => {
	let scrolled = document.scrollingElement.scrollTop,
		headerHeight = document.getElementsByTagName('header')[0].clientHeight;
	if(scrolled > headerHeight / 2){
		html.classList.add('no-menu');
	} else {
		html.classList.remove('no-menu');
	}
});
